import type { RouteLocationRaw } from 'vue-router';
import type { IMovieCard } from '~/types/movies';

enum EnumStatus {
  free = 'Бесплатно',
  buy = 'Покупка',
  subscribe = 'Подписка',
  defaultStatus = '',
}

export const useMovieCard = (movie: IMovieCard) => {
  const { subscription, isSpecialTariff } = storeToRefs(useAuthStore());

  const status = computed(() => EnumStatus[accessibility.value]);
  const route = computed((): RouteLocationRaw => ({ name: 'movie-slug', params: { slug: movie.slug } }));
  const showUnpublishedTag = computed(() => isSpecialTariff.value && movie?.has_unpublished);
  const isAvailableAdvertise = computed(() => movie.available_with_advertise_all ?? false);
  const isPurchasedFilm = computed(() => !movie.is_free && movie.is_available);
  const isSubscribed = computed(() => subscription.value?.is_active ?? false);
  const accessibility = computed(() => {
    if (movie.is_film) {
      if (isPurchasedFilm.value) return 'defaultStatus';
      if (movie.is_free && isAvailableAdvertise.value && !isSubscribed.value) return 'free';
      if (!movie.is_free) return 'buy';
      if (!movie.is_free && !movie.is_available && !isSubscribed.value) return 'subscribe';
      if (movie.is_free && isSubscribed.value) return 'defaultStatus';
      return 'subscribe';
    } else {
      if (isSubscribed.value) return 'defaultStatus';
      if (isAvailableAdvertise.value) return 'free';
      return 'subscribe';
    }
  });

  return {
    name: movie.name,
    image: movie?.poster_middle || movie?.img,
    imageHover: movie?.backdrop_mini || movie?.img,
    status,
    accessibility,
    labels: movie?.scheel ?? [],
    categories: movie?.categories ?? [],
    rating: {
      flex: movie?.rating,
      /*kp: movie?.kp,
      imdb: movie?.imdb,*/
    },
    film: {
      year: movie?.year,
      country: movie?.countries?.[0],
    },
    route,
    showUnpublishedTag,
    isSpecialTariff,
  };
};
