<script lang="ts" setup>
  import VButton from '~/components/ui/VButton.vue';
  import MovieVoteModal from './MovieVoteModal.vue';
  const { onReveal: openVoteModal } = createModal(MovieVoteModal);

  interface IProps {
    title: string;
    rating: number;
    slug: string;
    id: number;
  }
  const props = defineProps<IProps>();

  const { state } = storeToRefs(useAuthStore());
  const { onReveal } = useAuth();

  const handleAction = () => (state.value.loggedIn ? openVoteModal(props) : onReveal());
</script>

<template>
  <v-button
    class="movie-vote-button"
    :appearance="'outline'"
    color="white"
    icon="vote-star"
    media="normal"
    size="small"
    rounded
    @click="handleAction"
  />
</template>

<style lang="scss">
  .movie-vote-button {
    svg {
      fill: transparent !important;
    }
    &--voted svg {
      fill: $color-primary !important;
    }
  }
</style>
