<script lang="ts" setup>
  interface IProps {
    value?: string;
    color?: string;
    textColor?: string | null;
  }
  const props = defineProps<IProps>();
</script>

<template>
  <div class="movie-label" :style="{ background: props.color, color: props.textColor ?? 'black' }">{{ value }}</div>
</template>

<style lang="scss" scoped>
  .movie-label {
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    padding: 4px 12px;
    border-radius: 23px;
  }
</style>
