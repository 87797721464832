<script lang="ts" setup>
  import VModal from '../modals/VModal.vue';
  import MovieVote from './MovieVote.vue';
  import { setRatingMovie } from '~/services/modules/movies.service';
  import { useQueryClient } from '@tanstack/vue-query';

  const { mutate, isPending } = setRatingMovie();
  const queryClient = useQueryClient();

  interface IProps {
    title: string;
    rating: number;
    slug: string;
    id: number;
  }

  defineEmits<{
    close: [];
  }>();

  const props = defineProps<IProps>();

  const setNewRating = (newRating: number) => {
    mutate(
      {
        film: props.id,
        rating: newRating,
      },
      {
        onSuccess(data) {
          if (!props.slug) return;
          queryClient.setQueryData([props.slug], (oldData: any) =>
            oldData
              ? {
                  ...oldData,
                  my_rating: { amount: data?.rating },
                  rating: String(data?.updated_rating),
                }
              : oldData,
          );
        },
        onError(e) {
          console.log(e);
        },
      },
    );
  };
</script>

<template>
  <VModal class="vote-modal" size="large" @close="$emit('close')">
    <h2 class="vote-modal__title">{{ title }}</h2>
    <p class="vote-modal__subtitle">Поставте оценку</p>
    <MovieVote :loading="isPending" :rating="rating" @change="setNewRating" />
  </VModal>
</template>

<style lang="scss">
  .Toastify {
    z-index: 999999;
  }
  .vote-modal {
    color: $color-primary;
    &__title {
      font-size: 50px;
      font-weight: 700;
      line-height: 65px;
      margin-bottom: 32px;
    }
    &__subtitle {
      font-size: 20px;
      font-weight: 400;
      line-height: 28px;
      margin-bottom: 20px;
    }
  }
</style>
