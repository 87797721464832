<script setup lang="ts">
  import type { IMovieCard } from '~/types/movies';
  import type { TNullable } from '~/types/common';
  import type { RouteLocationRaw } from 'vue-router';
  const Player = defineAsyncComponent(() => import('~/components/player/Player.vue'));

  interface IProps {
    movie: IMovieCard;
    route: RouteLocationRaw;
    image: TNullable<string>;
  }

  const props = defineProps<IProps>();
</script>

<template>
  <div class="movie-card-preview">
    <div class="movie-card-preview__rating">18+</div>
    <player
      v-if="props.movie.newest_trailer"
      hide-quality
      muted
      autoplay
      :controls="['customControlSpacer', 'volumePanel', 'fullscreenToggle']"
      :url="props.movie.newest_trailer"
    />
    <router-link v-else :to="route">
      <v-image class="movie-card-preview__image" :src="image" :alt="movie.name" transition="fade">
        <template #loading></template>
        <template #error></template>
      </v-image>
    </router-link>
  </div>
</template>

<style scoped lang="scss">
  .movie-card-preview {
    height: 100%;
    position: relative;

    &__image {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    &__rating {
      position: absolute;
      display: grid;
      place-content: center;
      top: 20px;
      left: 20px;
      border-radius: 23px;
      background: rgba(0, 0, 0, 0.5);
      height: 2rem;
      padding: 0 1rem;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: $main_white;
      z-index: 20;
    }
  }
</style>
